.animation--flash {
  animation-name: flash;
  animation-duration: 300ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
@keyframes flash {
  0% {
    background-color: white;
  }
  50% {
    background-color: Red;
  }
}
