.clock {
  position: relative;
  font-size: 15vw;
  font-family: "Orbitron", sans-serif;
  &__seconds {
    // display: inline-block;
    // width: 25vw;
    // text-align: left;
  }
}
