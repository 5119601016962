.controls {
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: space-between;
  flex-wrap: wrap;
  @media all and (min-width: 800px) {
    flex-wrap: nowrap;
    width: 850px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    padding-right: 0;
  }
  &--fullscreen {
    height: 0;
    overflow: hidden;
  }
  &__input {
    width: 100%;
    height: 60px;
    margin-top: 20px;
    padding: 5px 10px;
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
    border: 1px solid #ccc;
    background: #fff;
    @media all and (min-width: 500px) {
      width: calc(50% - 10px);
    }
    @media all and (min-width: 800px) {
      width: 200px;
    }
  }
  &__button {
    width: 100%;
    height: 60px;
    margin-top: 20px;
    font-size: 18px;
    border: none;
    box-shadow: none;
    background: #f1f1f1;
    appearance: none;
    cursor: pointer;
    @media all and (min-width: 800px) {
      width: 200px;
    }
  }
}
